/* global __lodash__, Component , axios, defaultState */
class carDeliveryListComponent extends Component {

    static name() {
        return "carDeliveryList";
    }

    static componentName() {
        return "carDeliveryList";
    }

    data() {
        return {
            selectedCode:null,
            searchText:null,
            Comment:null,
            deliveryListState:[],
            selectedShipMode:null,
            selectedCC: null,
            loadding:true,
            newDeliveryModal:false
        };
    }

    getWatch() {
        return  {
            "$store.state.order_DelAddressCode": function (newValue,olValue){
                if(newValue != this.selectedCode)
                    this.selectedCode = newValue;
            },
            'selectedShipMode':function (newValue,oldValue){
                this.emitEvent("shipModeChange",newValue);
            },
            "deliveryList":function (newValue,oldValue){
                if(newValue && newValue.length == 1 && !this.selectedCode) {
                    this.selectedCode = newValue[0].Code;
                    this.$store.dispatch('updateOrderDeliveryData',{"order_DelAddressCode":newValue[0].Code});
                }
            },
            'shipModes':function (newValue,oldValue){
                if(this.shipModes.length > 0) {
                    this.selectedShipMode = this.shipModes[0].ShipModeCode;
                }
            }

        };
    }

    mounted() {
        return async  function () {
            if(this.$store.state.order_DelAddressCode)
                this.selectedCode = this.$store.state.order_DelAddressCode;
            this.$store.dispatch('updateOrderDeliveryData',{"order_ReceptionManagerCode": this.$store.getters.getSession.fields.Code});
            this.subscribeEvent("selectcentercost", this.centerCostCallback);
            this.loadCustomerAddresses();
            this.subscribeEvent("address-relocation",this.editAddress);
            this.subscribeEvent("address-relocation-cancel",this.editAddress);
        };
    }

    getComputed() {
        return {
            orderTemplate:function (){
                return  __lodash__.cloneDeep(this.$store.state.carOrderTemplate);
            },
            shipModes: function (){
                return this.$store.getters.getShipModes;
            },
            canAddNew:function (){
                return (this.isAdmin || this.deliveryList.length == 0) && !this.loadding;
            },
            filterDeliveryList:function (){
                let searchText = this.searchText;
                if(!searchText || searchText == '')
                    return this.deliveryList;
                else
                    return __lodash__.filter(this.deliveryList, function (delivery) {
                        let reg = new RegExp(`${searchText}`,'i');
                        return String(delivery.Name).match(reg) ||  String(delivery.Address).match(reg) || String(delivery.City).match(reg) || String(delivery.Province).match(reg) || String(delivery.ZipCode).match(reg);
                    });
            },
            centerCostFilter:function (){
                if(this.selectedCC) {
                    //console.log("Selected CC ",this.selectedCC.internalId)
                    return {masterId: this.selectedCC.internalId};
                }
                return {masterId:"-99999"};
            },
            deliveAddressByCustomer:function (){
                return this.$store.state.customer.DelAddressCode;
            },
            activeDayByZone:function (){
                let isDaysEnables = 0;
                let dayMap = this.$store.getters.getDaysByZone;
                for(let day in Object.keys(dayMap))
                    isDaysEnables += dayMap[day];
                return isDaysEnables > 0;
            },
            daysByZone:function (){
                return this.$store.getters.getDaysByZone;
            },
            deliveryList:function () {
                return  this.$store.getters.getCustomerAddresses;
            },
            shipItemCode:function (){
                return this.$store.getters.getShipItemCode;
            }
        };
    }

    getMethods() {
        return {
            editAddress:this.editAddress,
            loadCustomerAddresses:this.loadCustomerAddresses,
            activeDeliveryAddress:this.activeDeliveryAddress,
            activeAddress:this.activeAddress,
            updateSelectValues:this.updateSelectValues,
            openNewDelivery: this.openNewDelivery,
            createNewDelivey: this.createNewDelivey,
            openNewDeliveryModal:this.openNewDeliveryModal,
            centerCostCallback:this.centerCostCallback,
            isFreeShipCost:this.isFreeShipCost
        };
    }

    isFreeShipCost(freeShipCost){
        if(this.orderTemplate && this.orderTemplate.fields.Items && this.shipItemCode){
            let items = __lodash__.cloneDeep(this.orderTemplate.fields.Items);
            let shipModeCodes = this.shipItemCode.split(",");
            let shipCostItem = __lodash__.find(items,function (cost){
                return shipModeCodes.indexOf(cost.fields.ArtCode) !== -1;
            });
            if(shipCostItem) {
                if (this.$store.getters.getSetting.ShowPriceWithIVA)
                    return this.orderTemplate.fields.Total - shipCostItem.fields.VATPrice >= freeShipCost;
                else
                    return this.orderTemplate.fields.SubTotal - shipCostItem.fields.Price >= freeShipCost;
            }
        }
        if(this.orderTemplate) {
            if (this.$store.getters.getSetting.ShowPriceWithIVA)
                return this.orderTemplate.fields.Total >= freeShipCost;
            else
                return this.orderTemplate.fields.SubTotal >= freeShipCost;
        }
        return false;
    }

    async editAddress(address) {
        if(address.Longitude && address.Latitude && this.selectedCode == address.Code) {
            this.$store.dispatch('editAddressCustomer', address);
        }
    }

    loadCustomerAddresses() {
        const self = this;
        this.$store.dispatch('toogleLockScreen',null);
        this.$store.dispatch('loadCustomerAddresses').then(()=>{
            if(!this.selectedCode && self.filterDeliveryList.length > 0)
                self.activeAddress(self.filterDeliveryList[0].Code);
            self.$store.dispatch('toogleLockScreen',null);
        });
    }

    activeDeliveryAddress(delCode){
        if(this.selectedCode != null) {
            if (this.selectedCode == delCode)
                return true;
        }
        else {
            if (this.deliveAddressByCustomer == delCode){
                this.activeAddress (delCode);
                return true;
            }
        }
        return false;
    }

    activeAddress(code){
        let filterDL = this.deliveryList.filter(x=>x.Code == code);
        if(filterDL.length > 0){
            let delObj = filterDL[0];
            if(delObj.ZipCode)
                this.$store.dispatch('loadZoneFromZipCode', delObj.ZipCode);
            else
                this.$store.commit('updateDaysByZone', defaultState.daysByZone);

            let eventObj = {};
            eventObj[`order_DelDay`] = null;
            this.$store.dispatch('updateOrderDeliveryData',eventObj);
            if(!delObj.Latitude || !delObj.Longitude)
                this.emitEvent("addressNeedConfirmations",delObj);
        }
        this.selectedCode = code;
        $("#resetPoint").scrollTop(0);
        this.$store.dispatch('updateOrderDeliveryData',{'order_DelAddressCode':code});
        this.emitEvent('deliveryselected',{'order_DelAddressCode':code});
    }

    updateSelectValues(updateValue){
        //console.log('Casdadasd',updateValue,event.target.value);
        let eventObj = {};
        eventObj[`order_${updateValue}`] = event.target.value;
        this.$store.dispatch('updateOrderDeliveryData',eventObj);
    }

    openNewDelivery(){
        //console.log( "New Delivery" );
        this.$emit('new-delivery');
    }

    createNewDelivey(payload){
        return new Promise((resolve, reject) => {
            let self = this;
            payload['EntityType'] = 0;
            payload['Entity'] = this.$store.state.customer.Code;
            payload['EntityName'] = this.$store.state.customer.Name;
            axios.post('/ecommerce/api/saveRecord', {
                recordName: 'CompanyAddress',
                payload: payload
            })
                .then(function (response) {
                    //console.log(response.data);
                    if (response.data.success) {
                        self.deliveryList.push(response.data.record.fields);
                        self.activeAddress(response.data.record.fields.Code);
                    }
                    resolve(response.data);
                });
        });
    }

    openNewDeliveryModal(){
        this.newDeliveryModal = true;
        setTimeout(()=>{
            $("#newDeliveryModal").modal('show');
        },500);
        //$("#newDeliveryModal").modal('show');
    }

    centerCostCallback(event) {
        this.selectedCC = event;
        this.selectedCode = event.DelAddressCode;
        this.activeAddress(event.DelAddressCode);
    }

    getTemplate() {
        return `<div class="col-12">
                  <template v-if="newDeliveryModal">
                    <addressCreateComponent />
                  </template>
                  <div class="col-12" >
                    <div class="mt-3 bordered reset-point" id="resetPoint">
                         <div class="row m-0 align-items-center">
                            <h6 class="col-12 col-lg-6 align-items-center d-flex"><i class="delivery-icon fas fa-address-card fa-2x mr-3"></i>{{tr('Select a Delivery Address')}}</h6>
                            <div v-if="canAddNew" class="col-12 col-lg-6 text-left text-lg-right">
                                <div class="my-2" v-on:click="openNewDelivery">
                                    <button type="button" class="btn btn-light" @click="openNewDeliveryModal">
                                        <i class="fas fa-plus mr-2"></i>
                                        {{tr('New Delivery Address')}}
                                   </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 1" class="row m-0 align-items-start">
                            <div class="input-group col-12 col-lg-6">
                                <input class="form-control" v-model="searchText">
                                <div class="input-group-prepend">
                                  <div class="input-group-text"><i class="fa fa-search" /></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 0" class="row m-0 px-2">
                             <template v-for="delObj in filterDeliveryList">
                                 <template v-if="delObj.Code==selectedCode">
                                    <div class="col-12 col-sm-6 col-lg-2 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" v-on:click="activeAddress(delObj.Code)" >
                                          <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                          <p>{{delObj.Address}} </p>
                                          <p>{{delObj.Province}} </p>
                                          <p>{{delObj.City}} </p>
                                          <p>{{delObj.Locality}} </p>
                                          <p>{{delObj.ZipCode}} </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-for="delObj in filterDeliveryList">
                                <template v-if="delObj.Code!=selectedCode">
                                    <div class="col-12 col-sm-6 col-lg-2 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" v-on:click="activeAddress(delObj.Code)" >
                                          <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                          <p>{{delObj.Address}} </p>
                                          <p>{{delObj.Province}} </p>
                                          <p>{{delObj.City}} </p>
                                          <p>{{delObj.Locality}} </p>
                                          <p>{{delObj.ZipCode}} </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row new-address-message text-left text-md-right m-0">
                            <p class="col-12 mb-1">Puede administrar las direcciones en <router-link to="/profile/Addresses">Perfil > Direcciones</router-link></p>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-flip-horizontal fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <div class="form-group col-3">
                            <label for="inputEmail4">{{tr('Ship Mode')}}</label>
                            <select class="custom-select mb-3" v-model="selectedShipMode" v-on:change="updateSelectValues('ShipMode')">
                                <option value="null" selected>{{tr('Select One')}}</option>
                                <template v-for="shipMode of shipModes">
                                    <option :value="shipMode.ShipModeCode" >{{shipMode.ShipModeName}}</option>
                                </template>
                            </select>
                        </div>
                        <div class="form-group col-3">
                            <label for="inputEmail4">{{tr('Day')}}</label>
                            <select class="custom-select mb-3" v-on:change="updateSelectValues('DelDay')">
                                <option value="null" selected>{{tr('Select One')}}</option>
                                <option value="1" v-if="daysByZone.Monday">{{tr('Monday')}}</option>
                                <option value="2" v-if="daysByZone.Tuesday">{{tr('Tuesday')}}</option>
                                <option value="3" v-if="daysByZone.Wednesday">{{tr('Wednesday')}}</option>
                                <option value="4" v-if="daysByZone.Thursday">{{tr('Thursday')}}</option>
                                <option value="5" v-if="daysByZone.Friday">{{tr('Friday')}}</option>
                                <option value="6" v-if="daysByZone.Saturday" >{{tr('Saturday')}}</option>
                                <option value="7" v-if="daysByZone.Sunday">{{tr('Sunday')}}</option>
                            </select>
                        </div>
                        <div class="form-group col-3">
                            <label for="inputEmail4">{{tr('Reception Manager')}}</label>
                            <template v-if="this.selectedCC">
                                <selectLinkToComponent :recordKey="'Order_Delivery'"  class="form-control" table="CenterCostContactRow" paste="PersonCode" showField="PersonName" :selected="$store.state.session.fields.Code" :filters="centerCostFilter" @update-value="updateSelectValues('ReceptionManagerCode')"> </selectLinkToComponent>
                            </template>
                            <template v-else>
                                <select class="form-control mb-3 disabled" readonly disabled>
                                    <option :value="$store.state.session.fields.Code" selected> {{$store.state.session.fields.Name}} {{$store.state.session.fields.LastName}} </option>
                                </select>
                            </template>
                        </div>
                        <div class="form-group col-3">
                            <label for="inputEmail4">{{tr('Hours')}}</label>
                            <select class="custom-select mb-3" v-on:change="updateSelectValues('DelHour')">
                                <option value="0">9 a 13</option>
                                <option value="1">13 a 18</option>
                                <option value="2">9 a 18</option>
                            </select>
                        </div>
                        <div class="form-group col-3">
                            <label for="inputEmail4">{{tr('Purchase Order')}}</label>
                            <input class="form-control mb-3" v-on:change="updateSelectValues('CustPurchaseOrder')">
                        </div>
                    </div>
                  </div>
            </div>`;
    }
}

carDeliveryListComponent.registerComponent();